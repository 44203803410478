<template>
  <div class="row">
    <div class="col-12">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="설비별 점검결과"
          :isTitle="true"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :editable="editable && result.checkStatusCd!=='MCSC000015'"
          :data="result.equipmentResultList"
          :gridHeightAuto="true"
          :merge="grid2.merge"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          checkDisableColumn="disable"
          :hideBottom="true"
          noDataLabel="설비에 대한 점검항목이 없습니다."
          selection="multiple"
          rowKey="minEquipmentTypeCheckItemId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="정비요청" v-if="editable" icon="add" @btnClicked="linkClick2" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'customDetail'">
              <q-btn
                flat
                size="12px"
                color="orange"
                icon="chevron_right"
                @click="rowClickDetail(props.row, props.pageIndex)" />
            </template>
            <template v-else-if="props.row['cbmFlag'] === 'N'">
            </template>
            <template v-else-if="col.name === 'gaugeWarn'">
              <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
                {{ props.row.gaugeWarnLcl}}
              </font>
              <font style="font-size:1.0em;font-weight:700;">
                ~
              </font>
              <font style="font-size:1.0em;font-weight:700;" color="#C10015">
                {{ props.row.gaugeWarnUcl}}
              </font>
            </template>
            <template v-else-if="col.name === 'gaugeExec'">
              <font style="font-size:1.0em;font-weight:700;" color="#0300c1">
                {{ props.row.gaugeExecLcl}}
              </font>
              <font style="font-size:1.0em;font-weight:700;">
                ~
              </font>
              <font style="font-size:1.0em;font-weight:700;" color="#C10015">
                {{ props.row.gaugeExecUcl}}
              </font>
            </template>
            <template v-else-if="col.name === 'cbmAlert'">
              <q-chip
                :color="setTagColor(props.row)"
                :clickable="false"
                :class="null"
                text-color="white">
                {{setTagName(props.row)}}
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    
    <c-dialog :param="popupOptions"></c-dialog>
    <q-dialog v-model="dialogDetail" :position="positionDetail">
      <q-card style="width: 100%">
        <q-linear-progress :value="1" color="pink" />
        <q-card-section class="row">
          <div class="col-12 text-weight-bold-dailog" v-text="selectedRow.checkItemName"></div>
          <div class="col-4"><div class="mini-dailog-title">부품</div></div>
          <div class="col-8" v-text="selectedRow.checkItemPartName"></div>
          <div class="col-4"><div class="mini-dailog-title">검사방법</div></div>
          <div class="col-8" v-text="selectedRow.checkMethodName"></div>
          <div class="col-4"><div class="mini-dailog-title">점검결과</div></div>
          <div class="col-12">
            <c-text
              :editable="editable && result.checkStatusCd!=='MCSC000015'"
              label=""
              name="dialogcheckResult"
              v-model="dialogcheckResult">
            </c-text>
          </div>
          <div class="col-4"><div class="mini-dailog-title">개선진행 상태</div></div>
          <div class="col-8 mini-dailog-btn-impr">
            <div v-if="editable && result.checkStatusCd!=='MCSC000015'">
              <q-btn-group outline>
                <c-btn
                  v-if="editable&&result.checkStatusCd!=='MCSC000015'&&selectedRow.minEquipmentTypeCheckItemId"
                  :showLoading="false"
                  label="개선요청"
                  color="red-6"
                  text-color="white"
                  @btnClicked="innerBtnClicked(selectedRow, '1')"
                />
                <c-btn
                  v-if="editable&&result.checkStatusCd!=='MCSC000015'&&selectedRow.minEquipmentTypeCheckItemId"
                  :showLoading="false"
                  label="즉시조치"
                  color="light-blue"
                  text-color="white"
                  @btnClicked="innerBtnClicked(selectedRow, '2')"
                />
              </q-btn-group>
            </div>
          </div>
          <div class="col-12 border-impr-dialog">
            <template v-if="selectedRow['sopImprovementIds'] && selectedRow['ibmStepNames']">
              <q-chip
                v-for="(item, index) in $comm.ibmTagItems(selectedRow)"
                :key="index"
                :color="item.color"
                :clickable="true"
                text-color="white"
                style="margin-bottom:4px !important;"
                @click="linkClick(item, selectedRow)">
                {{item.title}}
                <!-- <c-assess-tooltip 
                  :ibmTooltip="item.ibmTooltip"
                  :ibmClassCd="item.ibmClassCd"
                /> -->
              </q-chip>
            </template>
            <template v-else-if="!selectedRow['minEquipmentTypeCheckItemId']">
              저장 후 개선요청이 가능합니다.
            </template>
            <template v-else>
              등록된 개선요청 및 즉시조치가 없습니다.
            </template>
          </div>
          <div class="col-4"><div class="mini-dailog-title">판정기준</div></div>
          <div class="col-12" v-text="dialogdecisionBasis"></div>
          <div class="col-4"><div class="mini-dailog-title">점검시 안전사항</div></div>
          <div class="col-12" v-text="dialogcheckCaution"></div>
          <div class="col-12 mini-dailog-btn-impr">
            <q-btn-group outline>
              <c-btn
                :showLoading="false"
                label="닫기"
                icon="close"
                @btnClicked="dialogClose"
              />
              <c-btn
                v-if="editable && result.checkStatusCd!=='MCSC000015'"
                :showLoading="false"
                label="적용"
                icon="check"
                color="amber-7"
                @btnClicked="dialogSubmit"
              />
            </q-btn-group>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result-detail',
  props: {
    result: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentName: '',
        checkStatusCd: '',
        minEquipmentCheckId: '',
        equipmentResultList: [],
        equipmentResultCbmList: [],
        checkDate: '',
      }),
    },
  },
  data() {
    return {
      dialogcheckResult: '',
      dialogdecisionBasis: '',
      dialogcheckCaution: '',
      dialogDetail: false,
      positionDetail: 'bottom',
      selectedRow: {},
      selectedRowIdx: -1,
      editable: true,
      tab: 'resultItem',
      grid2: {
        merge: [
          { index: 0, colName: "checkItemName" }],
        columns: [
            // {
            //   name: 'checkItemPartName',
            //   field: 'checkItemPartName',
            //   label: '부품',
            //   style: 'width:150px',
            //   align: 'left',
            //   sortable: false,
            // },
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            style: 'width:90px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'judgmentResultTypeCd',
            field: 'judgmentResultTypeCd',
            label: '판정',
            align: 'center',
            style: 'width:100px',
            type: 'select',
            setHeader: true,
            comboItems: [
              { code: 'MCRC000005', codeName: '적합' },
              { code: 'MCRC000010', codeName: '부적합' },
              { code: 'MCRC000015', codeName: '대상외' },
            ],
            sortable: false,
          },
          {
            name: 'cbmValue',
            field: 'cbmValue',
            label: '값',
            align: 'center',
            type: 'number',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'gaugeWarn',
            field: 'gaugeWarn',
            label: '경고값',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'gaugeExec',
            field: 'gaugeExec',
            label: '실행값',
            align: 'center',
            type: 'custom',
            style: 'width:70px',
            sortable: false,
          },
          // {
          //   name: 'checkMethodName',
          //   field: 'checkMethodName',
          //   label: '검사방법',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true
          // },
          {
            name: 'customDetail',
            field: 'customDetail',
            label: '',
            align: 'center',
            type: 'custom',
            style: 'width:40px',
            sortable: false
          },
        ],
        data: [],
        height: '500px',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ],
      title: '',
      insertUrl: '',
      updateUrl: '',
      updateItemUrl: '',
      getUrl: '',
      isSave: false,
      isSave2: false,
      isSave3: false,
      updateMode: false,
      equipmentCdName: '',
      colorItems: [],
      requestImprRow: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.updateItemUrl = transactionConfig.sop.min.equipment.result.updateItem.url;
      // list setting
    },
    saveItemInspection() {
      if (this.$comm.validTable(this.grid2.columns, this.result.equipmentResultList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.result.equipmentResultList, _item => {
              _item.minEquipmentCheckId = this.result.minEquipmentCheckId;
            })
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    rowClickDetail(_row, _idx) {
      this.dialogDetail = true;
      this.selectedRow = _row;
      this.selectedRowIdx = _idx;
      this.dialogcheckResult = _row.checkResult;
      this.dialogdecisionBasis = _row.decisionBasis;
      this.dialogcheckCaution = _row.checkCaution;
    },
    dialogSubmit() {
      this.$set(this.result.equipmentResultList[this.selectedRowIdx], 'checkResult', this.dialogcheckResult);
      this.$set(this.result.equipmentResultList[this.selectedRowIdx], 'editFlag', 'U');
      this.$set(this.result.equipmentResultList[this.selectedRowIdx], 'chgUserId', this.$store.getters.user.userId);
      this.dialogDetail = false;
    },
    dialogClose() {
      this.dialogDetail = false;
    },
    innerBtnClicked(row, btn) {
      this.requestImprRow = row
      let requestContents = row.checkItemName
      if (btn === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: row.minEquipmentTypeCheckItemId,
        ibmTaskTypeCd: 'ITT0000055',
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: row.minEquipmentTypeCheckItemId,
        ibmTaskTypeCd: 'ITT0000055',
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprImmDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      if (data.ibmClassCd === 'IC00000001') {
        this.popupOptions.title = '개선';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '즉시조치';
        this.popupOptions.param = {
          sopImprovementId: data.sopImprovementId,
        };
        this.popupOptions.target = () => import(`${'@/pages/ibm/imprImmDetail.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      }
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      // this.getDetail();
      if (data && data.col1) {
        let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
        if (sData.indexOf(data.col1.sopImprovementId) === -1) {
          this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
            this.requestImprRow.sopImprovementIds + ',' + data.col1.sopImprovementId : data.col1.sopImprovementId
          this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
            this.requestImprRow.ibmStepNames + ',' + data.col1.ibmStepName : data.col1.ibmStepName
          this.requestImprRow.ibmClassCds = this.requestImprRow.ibmClassCds ? 
            this.requestImprRow.ibmClassCds + ',' + data.col1.ibmClassCd : data.col1.ibmClassCd
          this.requestImprRow.ibmTooltip = this.requestImprRow.ibmTooltip ? 
            this.requestImprRow.ibmTooltip + '│' + data.col1.ibmTooltip : data.col1.ibmTooltip
        }
        this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
        this.$refs['grid2'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    closeImprPopup(result, flag) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      // this.getDetail();

      if (result && result.col1) {
        if (flag !== 'delete') {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitStep[idIndex] = result.col1.ibmStepName;
            this.requestImprRow.ibmStepNames = splitStep.toString();
          }
        } else {
          let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
          let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
          let splitClass = this.$_.split(this.requestImprRow.ibmClassCds, ',');
          let splitTooltip = this.$_.split(this.requestImprRow.ibmTooltip, '│');
          if (splitId && splitId.length > 0) {
            let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
            splitId.splice(idIndex, 1)
            splitStep.splice(idIndex, 1)
            splitClass.splice(idIndex, 1)
            splitTooltip.splice(idIndex, 1)
            this.$set(this.requestImprRow, 'sopImprovementIds', splitId.toString())
            this.$set(this.requestImprRow, 'ibmStepNames', splitStep.toString())
            this.$set(this.requestImprRow, 'ibmClassCds', splitClass.toString())
            this.$set(this.requestImprRow, 'ibmTooltip', splitTooltip.join('│'))
          }
        }
        this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
        this.$refs['grid2'].$refs['compo-table'].resetVirtualScroll();
      }
    },
    setTagColor(row) {
      if (Number(row.gaugeExecLcl) >= Number(row.cbmValue) || Number(row.gaugeExecUcl) <= Number(row.cbmValue)) {
        return 'red'
      } else if (Number(row.gaugeWarnLcl) >= Number(row.cbmValue) || Number(row.gaugeWarnUcl) <= Number(row.cbmValue)) {
        return 'orange'
      } else {
        return 'blue'
      }
    },
    setTagName(row) {
      if (Number(row.gaugeExecLcl) >= Number(row.cbmValue) || Number(row.gaugeExecUcl) <= Number(row.cbmValue)) {
        return '정비요청필요'
      } else if (Number(row.gaugeWarnLcl) >= Number(row.cbmValue) || Number(row.gaugeWarnUcl) <= Number(row.cbmValue)) {
        return '경고'
      } else {
        return '정상'
      }
    },
    linkClick2() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '점검항목을 선택하세요.', // 공기구를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let title = this.result.equipmentName;
        this.$_.forEach(selectData, _item => {
          title +=  '|' + _item.checkItemPartName + '|' + _item.checkItemName + '☞' + _item.cbmTypeName
           + '[경고값: ' + _item.gaugeWarnLcl + '~' + _item.gaugeWarnUcl + '|실행값: ' + _item.gaugeExecLcl + '~' + _item.gaugeExecUcl +']';
        })
        let date  =this.result.checkDate ? this.result.checkDate : this.result.checkScheduleDate
        this.popupOptions.title = '작업요청 상세';
        this.popupOptions.param = {
          woRequestId: '',
          plantCd: '',
          minEquipmentCheckId: this.result.minEquipmentCheckId,
          equipmentCd: this.result.equipmentCd,
          remark: title,
          woRequestName: date + ' 예방점검에 대한 정비요청',
          funcLocationCd: this.result.funcLocationCd,
          costCenterCd: this.result.costCenterCd,
          woRequestDt: date,
          woWorkTypeCd: 'WWTC000007',
        };
        this.popupOptions.target = () => import(`${'@/pages/wod/workOrderRequestDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = false;
        this.popupOptions.width = '90%';
        this.popupOptions.closeCallback = this.closePopup;

        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>
